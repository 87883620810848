import React, { useState, useEffect, useRef } from "react";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import { login } from '../services/bffServices/login';
import Card from 'react-bootstrap/Card';
import Container from "react-bootstrap/esm/Container";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Cookies from 'universal-cookie';
import { addTax } from '../services/bffServices/updateShoppingCart';
import { submitPO } from '../services/bffServices/submitOrder';
// import { updateSCCustomerInfo } from '../services/bffServices/updateSCCustomer';
import Accordion from 'react-bootstrap/Accordion';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import { duplicateUserCheckByEmail } from '../services/bffServices/getUserDetails';
import validator from 'validator';
import Spinner from 'react-bootstrap/Spinner'
import bcrypt from '../../node_modules/bcryptjs';
// import CreditCardForm from '../components/checkout/creditCardDetailsComponent';
import {
    Player,
    ControlBar,
    ReplayControl,
    ForwardControl,
    CurrentTimeDisplay,
    TimeDivider,
    PlaybackRateMenuButton,
    VolumeMenuButton
} from 'video-react';
import { encryptPaymentDetails, encryptTransactionDetails } from '../services/jwtPaymentTransformer';
import { getSalesTax } from '../services/salesTax/getSalesTax';
import { getSession, getLtiSession, getExistingSession } from '../services/bffServices/getSessionInfo';
// import GooglePayComponent from '../components/checkout/googlePay';
// import { Logging } from '@google-cloud/logging';

const saltRounds = 10;

const cookies = new Cookies();

function Checkout() {
    const [userData, setUserData] = useState({});
    const [productItem, setproductItem] = useState({});
    const [cartTotal, setcartTotal] = useState({});
    const [email, setemail] = useState();
    const [userName, setUserName] = useState({});
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [password, setPassword] = useState("");
    const [country, setCountry] = useState("");
    const [province, setProvince] = useState("");
    const [postalCode, setPostalCode] = useState("");
    const [duplicateMsg, setDuplicateMsg] = useState("");
    const [stepupError, setStepupError] = useState("")
    const [cc, setcc] = useState("");
    const [expiry, setExpiry] = useState("");
    const [cvv, setcvv] = useState("");
    const [paymentError, setPaymentError] = useState("");
    const [submitButton, setSubmitButton] = useState("Submit");
    const [disableSubmitButton, setDisableSubmitButton] = useState(false);
    const [blah, setBlah] = useState("0");
    const [coupon, setCoupon] = useState("0");
    const [pageError, setPageError] = useState("0");
    const [ltiDisabled, setLtiDisabled] = useState(false);
    const [existingUserInd, setExistingUserIn] = useState(false);

    //languageLabels
    const [emailLabel, setemailLabel] = useState("");
    const [usernameLabel, setusernameLabel] = useState("");
    const [passwordLabel, setpasswordLabel] = useState("");
    const [firstNameLabel, setfirstNameLabel] = useState("");
    const [lastNameLabel, setlastNameLabel] = useState("");
    const [countryLabel, setcountryLabel] = useState("");
    const [provinceLabel, setprovinceLabel] = useState("");
    const [postalLabel, setpostalLabel] = useState("");
    const [userInformationLabel, setuserInformationLabel] = useState("");
    const [priceBreakdownLabel, setpriceBreakdownLabel] = useState("");
    const [paySubmitLabel, setpaySubmitLabel] = useState("");
    const [checkoutLabel, setcheckoutLabel] = useState("");
    const [shareEmailLabel, setshareEmailLabel] = useState("");
    const [nextButtonLabel, setnextButtonLabel] = useState("");
    const [submitButtonLabel, setsubmitButtonLabel] = useState("");
    const [leftSideContent, setleftSideContent] = useState("");
    const [postalDescLabel, setpostalDescLabel] = useState("");
    const [zipDescLabel, setzipDescLabel] = useState("");
    const [regionDescLabel, setregionDescLabel] = useState("");
    const [creditCardRequired, setCreditCardRequired] = useState(true);
    const [counponHeading, setCouponHeading] = useState("");
    const [couponButton, setCouponButton] = useState("");
    const [subTotalLabel, setSubTotalLabel] = useState("");
    const [discountLabel, setDiscountLabel] = useState("");
    const [taxLabel, setTaxLabel] = useState("");
    const [totalLabel, setTotalLabel] = useState("");
    const [ccNumLabel, setCCNumLabel] = useState("");
    const [expiryLabel, setExpiryLabel] = useState("");
    const [cvvLabel, setCVVLabel] = useState("");
    const [authCCLabel, setAuthCCLabel] = useState("");
    const [authProcess, setAuthProcessLabel] = useState("");

    const couponForm = useRef(null);
    const scURL = process.env.REACT_APP_BFF + '/getShoppingCart/' + sessionStorage.getItem('sb-sc');
    const optionsPOST = {
        method: 'GET',
        headers: new Headers({
            'Content-Type': 'application/json',
            'sb-application-name': 'sb-site-ui',
            'sb-application-key': 'jmd-test',
            'sessionID': sessionStorage.getItem("sessionID"),
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': 'Access-Control-Allow-Origin, Access-Control-Allow-Headers, Content-Type, sb-application-name, sb-application-key, sessionID ',
        }),
        mode: 'cors',
        credentials: 'omit'
    };
    useEffect(async () => {

        setSCData();
        const language = sessionStorage.getItem("lang") || "EN"
        const ltiIndicator = sessionStorage.getItem("isLTI") || "N";
        const existingUser = sessionStorage.getItem("existingUser") || "N";

        if (existingUser == "Y") {
            setExistingUserIn(true);
            const sessionInfo = await getExistingSession();
            setLtiDisabled(true);
            setFirstName(sessionInfo.payload.firstName)
            setemail(sessionInfo.payload.email)
            setLastName(sessionInfo.payload.lastName)
        }

        if (ltiIndicator == "Y") {
            var ltiDetails = await getLtiSession();

            if (ltiDetails.ltiEmail != null || ltiDetails.ltiEmail != undefined) {
                setLtiDisabled(true);
                setFirstName(ltiDetails.firstName);
                setemail(ltiDetails.ltiEmail);
                setLastName(ltiDetails.lastName);
            }
        }

        if (language == "FR") {
            setemailLabel("e-mail");
            setusernameLabel("Nom d'utilisateur");
            setpasswordLabel("Mot de passe");
            setfirstNameLabel("Prénom");
            setlastNameLabel("Last Name");
            setcountryLabel("Pays");
            setprovinceLabel("Province");
            setpostalLabel("code postal");
            setuserInformationLabel("Informations de l'utilisateur");
            setpriceBreakdownLabel("Répartition des achats");
            setpaySubmitLabel("Paiement et soumettre la commande");
            setcheckoutLabel("Vérifier");
            setshareEmailLabel("Nous ne partagerons jamais votre adresse e-mail");
            setnextButtonLabel("Prochaine");
            setsubmitButtonLabel("Soumettre");
            setpostalDescLabel("Le code postal et la province sont utilisés pour valider les informations de paiement/facturation par carte de crédit et appliquer les taxes correctes");
            setzipDescLabel("Le code postal et l'état sont utilisés pour valider les informations de paiement/facturation par carte de crédit et appliquer les taxes correctes");
            setregionDescLabel("Le code postal/code postal et l'état/la province sont utilisés pour valider les informations de paiement/facturation par carte de crédit et appliquer les taxes correctes");
            setCouponHeading("Avez-vous un coupon?");
            setCouponButton("Appliquer la réduction");
            setDiscountLabel("Rabais");
            setTaxLabel("Impôt");
            setTotalLabel("Le total");
            setCCNumLabel("Numéro de Carte de Crédit");
            setExpiryLabel("Expiration");
            setCVVLabel("CVV");
            setAuthCCLabel("Carte de crédit");
            setAuthProcessLabel("Traitement");

            setleftSideContent(() => {
                return (
                    <>
                        <div align="center">
                            <h4>
                                Pourquoi des manuels animés et interactifs ?
                            </h4>
                        </div>
                        <div>
                            <small>
                                Nos manuels animés représentent une approche très visuelle pour comprendre la biologie et une alternative attendue depuis longtemps au manuel traditionnel. Nous voulons que les étudiants non seulement mémorisent, mais comprennent vraiment la biologie.<br /><br />
                                Nous intégrons donc des données de toute la littérature pour créer une solution complète de didacticiels de biologie contenant des animations précises et de haute qualité, des structures interactives 3D, des quiz en cours, du texte, des chiffres, et bien plus encore, tous représentatifs de tout ce que nous savons aujourd'hui sur un sujet donné. matière..
                                <br /><br />
                                Les élèves peuvent désormais voir, entendre et interagir avec les atomes, les gènes, les cellules, l'évolution, etc.
                            </small> <br /><br /></div>
                    </>
                )
            });
        }

        if (language == "EN") {
            setemailLabel("eMail");
            setusernameLabel("Username");
            setpasswordLabel("Password");
            setfirstNameLabel("First Name");
            setlastNameLabel("Last Name");
            setcountryLabel("Country");
            setprovinceLabel("Province");
            setpostalLabel("Postal Code");
            setuserInformationLabel("User Information");
            setpriceBreakdownLabel("Purchase Breakdown")
            setpaySubmitLabel("Payment & Submit Order");
            setcheckoutLabel("Checkout");
            setshareEmailLabel("We will never share your email address");
            setnextButtonLabel("Next");
            setsubmitButtonLabel("Submit")
            setpostalDescLabel("Postal Code & Province are used to validate credit card payment/billing information & apply the correct taxes")
            setzipDescLabel("Zip Code & State are used to validate credit card payment/billing information & apply the correct taxes")
            setregionDescLabel("Zip/Postal Code & State/Province are used to validate credit card payment/billing information & apply the correct taxes")
            setCouponHeading("Have an Access Code?")
            setCouponButton("Apply Access Code")
            setDiscountLabel("Discount");
            setTaxLabel("Tax");
            setTotalLabel("Total");
            setCCNumLabel("Credit Card Number")
            setExpiryLabel("Expiry")
            setCVVLabel("CVV")
            setAuthCCLabel("Credit Card")
            setAuthProcessLabel("Processing")
            setleftSideContent(() => {
                return (
                    <>
                        <div align="center">
                            <h4>
                                Why animated, interactive textbooks?
                            </h4>
                        </div>
                        <div>
                            <small>
                                Our Animated Textbooks represent a highly visual approach to understanding biology and a long overdue alternative to the traditional textbook. We want students to not just memorize, but truly understand biology.
                                <br /><br />
                                We therefore integrate data from across the literature to create comprehensive resources consisting of high quality, accurate animations, 3D interactive structures, text, figures, LMS-quizzes, and much more, all representative of everything that we know today on a given subject.<br /><br />
                                Students can now see, hear, and interact with atoms, genes, cells, evolution, and more.
                            </small> <br /><br /></div>
                    </>
                )
            });
        }
    }, []);

    const setSCData = async () => {

        var totalDiscounts = 0;
        const scStuff = await fetch(scURL, optionsPOST);
        const thatStuff = await scStuff.json();
        var productItem = await thatStuff.cartItems;
        var totals = await thatStuff.cartTotal;

        if (totals != undefined || totals != null) {
            if (totals.cartTotal == 0) {
                setCreditCardRequired(false);
            }
        }

        setUserData(thatStuff);
        setproductItem(productItem);
        setcartTotal(totals);
    }

    const updateCustomerInfo = event => {
        setemail(email);
    }

    const validateZipCode = async (postalCode) => {
        setStepupError("");
        const taxInfo = await getSalesTax({
            country: country,
            provinceOrState: province,
            postalOrZipCode: postalCode,
            shoppingCartId: sessionStorage.getItem('sb-sc'),
            orderAmount: productItem[0]?.productPrice?.toFixed(2)
        })

        if (taxInfo.error) {
            setStepupError(() => {
                return (
                    <>
                        <Alert key="danger" variant="danger">
                            {taxInfo.detail}
                        </Alert>
                    </>
                )
            });
        }
        if (!taxInfo.error) {
            setPostalCode(postalCode);
        }
    }

    const addCoupon = async () => {

        const updateCoupon = process.env.REACT_APP_BFF + "/updateShoppingCart";
        const body = {
            "shoppingCartId": sessionStorage.getItem('sb-sc'),
            "updateType": "coupon",
            "couponCode": coupon
        }
        const optionsCoupon = {
            method: 'PATCH',
            headers: new Headers({
                'Content-Type': 'application/json',
                'sb-application-name': 'sb-site-ui',
                'sb-application-key': 'jmd-test',
                'Access-Control-Allow-Origin': '*',
                'sessionID': sessionStorage.getItem("sessionID"),
                'Access-Control-Allow-Headers': 'Access-Control-Allow-Origin, Access-Control-Allow-Headers, Content-Type, sb-application-name, sb-application-key, sessionID ',
            }),
            mode: 'cors',
            credentials: 'omit',
            body: JSON.stringify(body)
        };

        await fetch(updateCoupon, optionsCoupon);
        setSCData();
    }

    async function applyTax() {
        updateCustomerInfo();
        const payload = {
            "province": "ON",
            "scId": userData.shoppingCartID,
            "tax": {
                "hst": await getSalesTax(province, country)
            }
        }

        await addTax(payload);
        setSCData();
    }

    function spinnerEnabled() {
        return (
            <div>
                <Spinner animation="border" size="sm" />
            </div>
        );
    }

    async function submitProductOrder() {

        var payload = {};
        setPaymentError("");

        setsubmitButtonLabel(() => {
            return (
                <>
                    <Spinner
                        as="span"
                        animation="grow"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                    />
                </>
            )
        })
        var allFieldsCompleted = false;
        if (cc != "" && expiry != "" && cvv != "") {
            allFieldsCompleted = true;
        } else {
            allFieldsCompleted = false;

            if (!creditCardRequired) {
                allFieldsCompleted = true;
            }
        }

        if (allFieldsCompleted) {
            if (existingUserInd == false) {
                try {
                    var encryptedPassword = await bcrypt.hash(password, saltRounds, async function (err, hash2) {
                        payload = {
                            "shoppingCartId": sessionStorage.getItem('sb-sc'),
                            "fName": firstName || "",
                            "lName": lastName || "",
                            "userName": email,
                            "password": hash2,
                            "email": email,
                            "existingUser": existingUserInd,
                            "addressInfo": {
                                "country": country,
                                "province": province,
                                "postalCode": postalCode

                            },
                            "ccDetails": {
                                "cardNum": cc,
                                "cardExpiry": expiry,
                                "cvv": cvv
                            },
                            "transactionDetails": {
                                "transactionNum": sessionStorage.getItem('sb-sc'),
                                "tax": cartTotal?.cartTaxValue,
                                "totalAmount": cartTotal?.cartTotal,
                                "currency": cartTotal?.cartCurrency
                            }
                        }
                        await encryptPaymentDetails(payload.ccDetails);
                        await encryptTransactionDetails(payload.transactionDetails);

                        setDisableSubmitButton(true)
                        setSubmitButton(spinnerEnabled);

                        const results = await submitPO(payload);

                        if (results.results == "SUCCESS") {
                            if (existingUserInd == false) {
                                login(payload.email, payload.password);
                            }
                            await sessionStorage.removeItem('sb-sc');
                            await sessionStorage.setItem('sc-orderId', results.id);

                            window.location.href = "/thankyou";
                        }

                        if (results.results != "SUCCESS") {
                            if (results.errorCode == 62 || results.errorCode != null || results.errorCode != undefined) {
                                setPaymentError(() => {
                                    return (
                                        <>
                                            <Alert key="danger" variant="danger">
                                            Your transaction was unsuccessful. Please double-check your address and credit card details, then try again.  If issue persistes, contact <a href="mailTo:support@smart-biology.com">support@smart-biology.com</a><br />
                                                <br />(Error: {results.errorMsg.message})
                                            </Alert>
                                        </>
                                    )
                                });

                            } else {
                                setPaymentError(() => {
                                    return (
                                        <>
                                            <Alert key="danger" variant="danger">
                                            Your transaction was unsuccessful, please confirm method of payment and retry.  If issue persistes, contact <a href="mailTo:support@smart-biology.com">support@smart-biology.com</a><br />
                                                <br />(Error: {results.errorMsg.message})
                                            </Alert>x
                                        </>
                                    )
                                });
                            }
                            setsubmitButtonLabel("Submit");
                            setDisableSubmitButton(false);
                        }
                    });
                } catch (err) {
                    // window.location.href = "/error?errMsg=" + err;
                    setPaymentError(() => {
                        return (
                            <>
                                <Alert key="danger" variant="danger">
                                    Transaction Failed with an unknown error. Please confirm method of payment and retry.  If issue persistes, contact <a href="mailTo:support@smart-biology.com">support@smart-biology.com</a><br />
                                    <br />(Error: {err || "Unknown Error Occured"})
                                </Alert>x
                            </>
                        )
                    });
                }
            }

            if (existingUserInd == true) {
                try {
                    payload = {
                        "shoppingCartId": sessionStorage.getItem('sb-sc'),
                        "fName": firstName || "",
                        "lName": lastName || "",
                        "userName": email,
                        "password": null,
                        "email": email,
                        "addressInfo": {
                            "country": country,
                            "province": province,
                            "postalCode": postalCode

                        },
                        "existingUser": existingUserInd,
                        "ccDetails": {
                            "cardNum": cc,
                            "cardExpiry": expiry,
                            "cvv": cvv
                        },
                        "transactionDetails": {
                            "transactionNum": sessionStorage.getItem('sb-sc'),
                            "tax": cartTotal?.cartTaxValue,
                            "totalAmount": cartTotal?.cartTotal,
                            "currency": cartTotal?.cartCurrency
                        }
                    }
                    await encryptPaymentDetails(payload.ccDetails);
                    await encryptTransactionDetails(payload.transactionDetails);

                    setDisableSubmitButton(true)
                    setSubmitButton(spinnerEnabled);

                    const results = await submitPO(payload);
                    if (results.errorCode != 62) {
                        if (existingUserInd == false) {
                            login(payload.email, payload.password);
                        }
                        sessionStorage.removeItem('sb-sc');
                        sessionStorage.setItem('sc-orderId', results.id);

                        window.location.href = "/thankyou";
                    }

                    if (results.errorCode == 62 || results.errorCode != null || results.errorCode != undefined) {
                        setPaymentError(() => {
                            return (
                                <>
                                    <Alert key="danger" variant="danger">
                                        {results.errorMsg}
                                    </Alert>
                                </>
                            )
                        });
                        setSubmitButton("Submit");
                        setsubmitButtonLabel("Submit")

                        setDisableSubmitButton(false);

                    }
                } catch (err) {
                    window.location.href = "/error?errMsg=" + err;
                }

            }
        } else {
            setPaymentError(() => {

                return (
                    <>
                        <Alert key="danger" variant="danger">
                            Please enter all Credit Card Details are added.
                        </Alert>
                    </>
                )
            });
            setDisableSubmitButton(false);
            setsubmitButtonLabel("Submit")

        }

    }
    function ButtonAddUserInfo({ eventKey }) {

        const decoratedOnClick = useAccordionButton(eventKey, async () => {
            updateCustomerInfo();
            const taxInfo = await getSalesTax({
                country: country,
                provinceOrState: province,
                postalOrZipCode: postalCode,
                shoppingCartId: sessionStorage.getItem('sb-sc'),
                orderAmount: productItem[0]?.productPrice?.toFixed(2)
            })

            const payload = {
                "province": province,
                "scId": sessionStorage.getItem('sb-sc'),
                "tax": {
                    "hst": taxInfo.taxTotalPercentage
                }
            }
            await addTax(payload);
            setSCData();
        });

        const validateZipCode = async (postalCode) => {
            setStepupError("");
            var taxInfo = "";

            if (country == "US") {
                taxInfo = await getSalesTax({
                    country: country,
                    provinceOrState: province,
                    postalOrZipCode: postalCode,
                    shoppingCartId: sessionStorage.getItem('sb-sc'),
                    orderAmount: productItem[0]?.productPrice?.toFixed(2)
                })
            }

            if (country == "CA") {
                if (!validator.isPostalCode(postalCode, "any")) {
                    taxInfo = {
                        "error": true,
                        "detail": "Invalid Postal Code"
                    }
                }
            }

            if (!taxInfo.error) {
                return true;
            }
        }

        const processAccount = async () => {

            if (existingUserInd == false) {
                if (password != "" && firstName != "" && lastName != "" && province != "" && postalCode != "" && email != "") {
                    var duplicateCheck = await duplicateUserCheckByEmail(email);
                    if (!duplicateCheck) {
                        setDuplicateMsg("")
                        setStepupError("");
                        if (validator.isEmail(email)) {

                            const isValidZip = await validateZipCode(postalCode);

                            if (isValidZip) {
                                decoratedOnClick();
                            }
                        } else {
                            setDuplicateMsg("incorrect email format!")
                        }
                    } else {
                        setDuplicateMsg("Email already used")
                    }
                } else {
                    setStepupError(() => {
                        return (
                            <>
                                <Alert key="danger" variant="danger">
                                    Entries missing, please validate all fields have been filled out!
                                </Alert>
                            </>
                        )
                    });
                }
            }

            if (existingUserInd) {
                if (province != "" && postalCode != "") {
                    decoratedOnClick();

                } else {
                    setStepupError(() => {
                        return (
                            <>
                                <Alert key="danger" variant="danger">
                                    Entries missing, please validate all fields have been filled out!
                                </Alert>
                            </>
                        )
                    });
                }
            }

        }

        return (
            <button
                class="btn btn-primary mb-2"
                type="button"
                onClick={processAccount}
            >
                {nextButtonLabel}
            </button>
        );
    }

    function ButtonPricingInfo({ eventKey }) {
        const decoratedOnClick = useAccordionButton(eventKey, async () => { });

        return (
            <button
                class="btn btn-primary mb-2"
                type="button"
                onClick={decoratedOnClick}
                style={{ 'pointer-events': "all" }}
            >
                {nextButtonLabel}
            </button>
        );
    }

    return (
        <div class="fill-window" style={{ 'background-color': "#f4f4f4" }}>
            <Container style={{ padding: "10px 0px 0px 0px", width: "100%" }} fluid="sm">
                <Card style={{ padding: "20px 0px 0px 0px", 'background-color': "#f4f4f4", border: "0px" }}>
                    <Row>
                        <Col align="left">
                            <Container>
                                {leftSideContent}
                                <div>
                                    <Player
                                        playsInline
                                        poster="https://storage.googleapis.com/sb-general/wp-content/uploads/2021/03/SB-Instructor-Resources-3.jpg"
                                        src="https://smart-biology-molecular-dna-replication.s3-us-west-2.amazonaws.com/Demo-Videos/Smart-Biology-90-Sec-Explainer-Video-Bit-30vs.mp4?_=1"
                                    >
                                        <ControlBar>
                                            <ReplayControl seconds={10} order={1.1} />
                                            <ForwardControl seconds={30} order={1.2} />
                                            <CurrentTimeDisplay order={4.1} />
                                            <TimeDivider order={4.2} />
                                            <PlaybackRateMenuButton rates={[5, 2, 1, 0.5, 0.1]} order={7.1} />
                                            <VolumeMenuButton disabled />
                                        </ControlBar>
                                    </Player>
                                </div><br />
                            </Container>
                        </Col>
                        <Col style={{ align: "left" }} sm={7}>
                            <Card style={{ align: "left" }}>
                                <Card.Body style={{ align: "left" }}>
                                    <Card.Header>
                                        <h4>{checkoutLabel}</h4>
                                    </Card.Header><br />
                                    <Accordion defaultActiveKey={blah}>
                                        <Accordion.Item eventKey="0">
                                            <Accordion.Header>{userInformationLabel}</Accordion.Header>
                                            <Accordion.Body>
                                                <small>
                                                    <table width="100%" align="center" border="0">
                                                        <tr>
                                                            <td width="20%">
                                                                <label>{emailLabel}</label>
                                                            </td>
                                                            <td>
                                                                <input class="form-control form-control-sm" value={email} onChange={event => setemail(event.target.value)} type="text" autoComplete="text" name="email" id="email" disabled={ltiDisabled} />
                                                                <div align="left" style={{ padding: "0px 0px 0px 10px", 'color': "grey" }}><small>{shareEmailLabel}</small></div>
                                                                <div style={{ 'color': "red", 'font-size': "10px", 'text-align': "left" }}>{duplicateMsg}</div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <label>{usernameLabel}</label>
                                                            </td>
                                                            <td>
                                                                <input class="form-control form-control-sm" type="text" autoComplete="text" onChange={event => setUserName(event.target.value)} name="username" id="username" value={email} disabled />
                                                            </td>
                                                        </tr>
                                                        {existingUserInd != true &&
                                                            <tr>
                                                                <td>
                                                                    <label>{passwordLabel}</label>
                                                                </td>
                                                                <td>
                                                                    <input class="form-control form-control-sm" type="password" autoComplete="password" onChange={event => setPassword(event.target.value)} name="password" id="password" />
                                                                </td>
                                                            </tr>
                                                        }
                                                        <tr>
                                                            <td colspan="2">
                                                                <hr />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <label>{firstNameLabel}</label>
                                                            </td>
                                                            <td>
                                                                <input class="form-control form-control-sm" type="text" autoComplete="text" value={firstName} onChange={event => setFirstName(event.target.value)} name="fname" id="fname" disabled={ltiDisabled} />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <label>{lastNameLabel}</label>
                                                            </td>
                                                            <td>
                                                                <input class="form-control form-control-sm" type="text" autoComplete="text" name="lname" value={lastName} onChange={event => setLastName(event.target.value)} id="lname" disabled={ltiDisabled} />
                                                            </td>
                                                        </tr>
                                                        {/* </>
                                                        } */}
                                                        <tr>
                                                            <td>
                                                                <label>{countryLabel}</label>
                                                            </td>
                                                            <td>
                                                                <Form.Select aria-label="Default select example" onChange={event => setCountry(event.target.value)} id="country" name="country" >
                                                                    <option value="0"></option>
                                                                    <option value="CA">Canada</option>
                                                                    <option value="US">United States</option>
                                                                    <option value="OTHER">Other</option>
                                                                </Form.Select>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                {country == "CA" &&
                                                                    <div>{provinceLabel}</div>
                                                                }
                                                                {country == "US" &&
                                                                    <div>State</div>
                                                                }
                                                                {country == "OTHER" &&
                                                                    <div>Region</div>
                                                                }
                                                            </td>
                                                            <td colspan="2">
                                                                <table width="100%" align="right">
                                                                    <tr>
                                                                        <td>
                                                                            {country == "CA" &&
                                                                                <Form.Select aria-label="Default select example" onChange={event => setProvince(event.target.value)} id="province" name="province" >
                                                                                    <option value="0"></option>
                                                                                    <option value="AB">Alberta</option>
                                                                                    <option value="BC">British Columbia</option>
                                                                                    <option value="MB">Manitoba</option>
                                                                                    <option value="NB">New Brunswick</option>
                                                                                    <option value="NL">Newfoundland and Labrador</option>
                                                                                    <option value="NS">Nova Scotia</option>
                                                                                    <option value="ON">Ontario</option>
                                                                                    <option value="PE">Prince Edward Island</option>
                                                                                    <option value="QC">Quebec</option>
                                                                                    <option value="SK">Saskatchewan</option>
                                                                                    <option value="NT">Northwest Territories</option>
                                                                                    <option value="NU">Nunavut</option>
                                                                                    <option value="YT">Yukon</option>
                                                                                </Form.Select>
                                                                            }
                                                                            {country == "US" &&
                                                                                <Form.Select aria-label="Default select example" onChange={event => setProvince(event.target.value)} id="province" name="province" >
                                                                                    <option value="0"></option>
                                                                                    <option value="AL">Alabama</option>
                                                                                    <option value="AK">Alaska</option>
                                                                                    <option value="AZ">Arizona</option>
                                                                                    <option value="AR">Arkansas</option>
                                                                                    <option value="CA">California</option>
                                                                                    <option value="CO">Colorado</option>
                                                                                    <option value="CT">Connecticut</option>
                                                                                    <option value="DE">Delaware</option>
                                                                                    <option value="FL">Florida</option>
                                                                                    <option value="GA">Georgia</option>
                                                                                    <option value="HI">Hawaii</option>
                                                                                    <option value="ID">Idaho</option>
                                                                                    <option value="IL">Illinois</option>
                                                                                    <option value="IN">Indiana</option>
                                                                                    <option value="IA">Iowa</option>
                                                                                    <option value="KS">Kansas</option>
                                                                                    <option value="KY">Kentucky</option>
                                                                                    <option value="LA">Louisiana</option>
                                                                                    <option value="ME">Maine</option>
                                                                                    <option value="MD">Maryland</option>
                                                                                    <option value="MA">Massachusetts</option>
                                                                                    <option value="MI">Michigan</option>
                                                                                    <option value="MN">Minnesota</option>
                                                                                    <option value="MS">Mississippi</option>
                                                                                    <option value="MO">Missouri</option>
                                                                                    <option value="MT">Montana</option>
                                                                                    <option value="NE">Nebraska</option>
                                                                                    <option value="NV">Nevada</option>
                                                                                    <option value="NH">New Hampshire</option>
                                                                                    <option value="NJ">New Jersey</option>
                                                                                    <option value="NM">New Mexico</option>
                                                                                    <option value="NY">New York</option>
                                                                                    <option value="NC">North Carolina</option>
                                                                                    <option value="ND">North Dakota</option>
                                                                                    <option value="OH">Ohio</option>
                                                                                    <option value="OK">Oklahoma</option>
                                                                                    <option value="OR">Oregon</option>
                                                                                    <option value="PA">Pennsylvania</option>
                                                                                    <option value="RI">Rhode Island</option>
                                                                                    <option value="SC">South Carolina</option>
                                                                                    <option value="SD">South Dakota</option>
                                                                                    <option value="TN">Tennessee</option>
                                                                                    <option value="TX">Texas</option>
                                                                                    <option value="UT">Utah</option>
                                                                                    <option value="VT">Vermont</option>
                                                                                    <option value="VA">Virginia</option>
                                                                                    <option value="WA">Washington</option>
                                                                                    <option value="WV">West Virginia</option>
                                                                                    <option value="WI">Wisconsin</option>
                                                                                    <option value="WY">Wyoming</option>
                                                                                </Form.Select>
                                                                            }
                                                                            {country == "OTHER" &&
                                                                                <input class="form-control form-control-sm" type="text" autoComplete="text" name="province" onChange={event => setProvince(event.target.value)} id="province" />
                                                                            }
                                                                        </td>

                                                                        {country == "CA" &&
                                                                            <>
                                                                                <td style={{ padding: "10px" }} width="20%">
                                                                                    <div>{postalLabel}</div>
                                                                                </td>
                                                                                <td width="30%">
                                                                                    <input class="form-control form-control-sm" settype="text" autoComplete="text" name="postalCode" id="postalCode" onChange={event => setPostalCode(event.target.value)} />
                                                                                </td>
                                                                            </>
                                                                        }
                                                                        {country == "US" &&
                                                                            <>
                                                                                <td style={{ padding: "10px" }} width="20%">

                                                                                    <div>Zip Code</div>
                                                                                </td>
                                                                                <td width="30%">
                                                                                    <input class="form-control form-control-sm" settype="text" autoComplete="text" name="postalCode" id="postalCode" onChange={event => setPostalCode(event.target.value)} />
                                                                                </td>
                                                                            </>
                                                                        }
                                                                        {country == "OTHER" &&
                                                                            <>
                                                                                <td style={{ padding: "10px" }} width="20%">
                                                                                    <div>Zip/Postal Code</div>
                                                                                </td>
                                                                                <td width="30%">
                                                                                    <input class="form-control form-control-sm" settype="text" autoComplete="text" name="postalCode" id="postalCode" onChange={event => setPostalCode(event.target.value)} />
                                                                                </td>
                                                                            </>
                                                                        }
                                                                    </tr>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colspan="2" align="right">
                                                                <Form.Text style={{ width: "50%" }} id="passwordHelpBlock" muted>
                                                                    {country == "CA" &&
                                                                        <>
                                                                            {postalDescLabel}
                                                                        </>

                                                                    }
                                                                    {country == "US" &&
                                                                        <>
                                                                            {zipDescLabel}
                                                                        </>

                                                                    }
                                                                    {country == "OTHER" &&
                                                                        <>
                                                                            {regionDescLabel}
                                                                        </>

                                                                    }

                                                                </Form.Text>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colspan="2" align="right" >
                                                                <ButtonAddUserInfo eventKey="1" /><br />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colspan="2" align="center">
                                                                {stepupError}
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </small>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="1" className="disabled-pointer-events">
                                            <Accordion.Header>{priceBreakdownLabel}</Accordion.Header>
                                            <Accordion.Body >
                                                <Row align="center">

                                                    <Col style={{ padding: "10px" }} sm={5}>
                                                        <Card >
                                                            <Card.Header>
                                                                <h5>{counponHeading}</h5>
                                                            </Card.Header>
                                                            <Card.Body>
                                                                <Card.Text>
                                                                    <input style={{ 'pointer-events': "all" }} class="form-control form-control-sm" type="text" autoComplete="text" onChange={event => setCoupon(event.target.value)} name="coupon" id="coupon" /><br />
                                                                    <Button style={{ 'pointer-events': "all" }} variant="outline-primary" onClick={() => addCoupon()} >{couponButton}</Button>
                                                                </Card.Text>
                                                            </Card.Body>
                                                        </Card>
                                                    </Col>
                                                    <Col sm={6}>
                                                        <table align="right" width="100%">
                                                            <tr>
                                                                <td align="left" style={{ padding: "5px" }}>
                                                                    {productItem[0]?.productName}<br />
                                                                </td>
                                                                <td align="right">
                                                                    ${productItem[0]?.productPrice?.toFixed(2)}<br />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td colspan="2">
                                                                    <hr />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td align="left">
                                                                    {subTotalLabel}
                                                                </td>
                                                                <td align="right">
                                                                    ${cartTotal?.cartSubtotal?.toFixed(2) || productItem[0]?.productPrice?.toFixed(2)}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td align="left">
                                                                    {discountLabel}
                                                                </td>
                                                                <td align="right">
                                                                    ${cartTotal?.cartTotalDiscountValue || '0'}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td align="left">
                                                                    Tax ({(cartTotal?.cartTotalTaxPercent * 100) || '0'}%)
                                                                </td>
                                                                <td align="right">
                                                                    ${cartTotal?.cartTaxValue?.toFixed(2) || '0'}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td align="left">
                                                                    {totalLabel}
                                                                </td>
                                                                <td align="right">
                                                                    ${cartTotal?.cartTotal?.toFixed(2) || productItem[0]?.productPrice?.toFixed(2)}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td colspan='2' align='right'>
                                                                    <ButtonPricingInfo eventKey="2" />
                                                                </td>
                                                            </tr>
                                                        </table><br /><br /><br /><br /><br /><br /><br /><br />
                                                    </Col>
                                                </Row>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="2" className="disabled-pointer-events">
                                            <Accordion.Header>{paySubmitLabel}</Accordion.Header>
                                            <Accordion.Body>
                                                <table width="100%">
                                                    {creditCardRequired != false &&
                                                        <>
                                                            {/* <CreditCardForm/>
                                                        </> */}
                                                            <tr>
                                                                <td>
                                                                    <label>{ccNumLabel}</label>
                                                                </td>
                                                                <td>
                                                                    <input style={{ 'pointer-events': "all" }} class="form-control form-control-sm" onChange={event => setcc(event.target.value.replace(/[^\d]/g, ""))} type="text" autoComplete="text" name="cc" id="cc" />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <label>{expiryLabel}</label>
                                                                </td>
                                                                <td>
                                                                    <input style={{ 'pointer-events': "all" }} placeholder="MMYY" maxLength={4} class="form-control form-control-sm" onChange={event => setExpiry(event.target.value.replace(/[^\d]/g, ""))} type="text" autoComplete="text" name="expiry" id="expiry" />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <label>{cvvLabel}</label>
                                                                </td>
                                                                <td>
                                                                    <input style={{ 'pointer-events': "all" }} class="form-control form-control-sm" onChange={event => setcvv(event.target.value.replace(/[^\d]/g, ""))} type="text" autoComplete="text" name="cvv" id="cvv" />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <label>{emailLabel}</label>
                                                                </td>
                                                                <td>
                                                                    <input style={{ 'pointer-events': "all" }} class="form-control form-control-sm" type="text" autoComplete="text" name="email" id="email" value={email} disabled />
                                                                </td>
                                                            </tr>
                                                        </>

                                                    }
                                                    <tr>
                                                        <td>
                                                            <div class="AuthorizeNetSeal" align="left"> <script type="text/javascript" language="javascript">var ANS_customer_id="2705aece-1ac4-41ed-963d-0bfd118659d8";</script>
                                                                <a href="https://verify.authorize.net/anetseal/?pid=2705aece-1ac4-41ed-963d-0bfd118659d8&amp;rurl=https://www.smart-biology.com" onmouseover="window.status='http://www.authorize.net/'; return true;" onmouseout="window.status=''; return true;" onclick="window.open('https://verify.authorize.net/anetseal/?pid=2705aece-1ac4-41ed-963d-0bfd118659d8&amp;rurl=https://www.smart-biology.com','AuthorizeNetVerification','width=600,height=430,dependent=yes,resizable=yes,scrollbars=yes,menubar=no,toolbar=no,status=no,directories=no,location=yes'); return false;" rel="noopener noreferrer" target="_blank">
                                                                    <img src="https://verify.authorize.net/anetseal/images/secure90x72.gif" width="90" height="72" border="0" alt="Authorize.Net Merchant - Click to Verify" />
                                                                </a>
                                                                <a href="http://www.authorize.net/" style={{ 'font-size': "10px", "text-decoration": "none", color: "black" }} id="AuthorizeNetText" target="_blank"><div><small>{authCCLabel} <br />{authProcess}</small></div></a>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div align="right">
                                                                <button type="submit" class="btn btn-primary mb-2" style={{ width: "100%", 'pointer-events': "all" }} onClick={() => submitProductOrder()} disabled={disableSubmitButton}>{submitButtonLabel}</button>
                                                                {creditCardRequired}
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </table><br />
                                                {/* <GooglePayComponent /> */}
                                                {paymentError}
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                </Card.Body>
                            </Card>

                        </Col>
                    </Row>
                </Card>
            </Container>
        </div>
    );
}


export default Checkout;